import React, { createContext, useContext, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const [planData, setPlanData] = useState(null);

  const addToast = (type, message) => {
    switch (type) {
      case "error":
        toast.error(message);
        break;
      case "success":
        toast.success(message);
        break;
      case "info":
        toast.info(message);
        break;
      default:
        toast(message);
        break;
    }
  };

  const setPlan = (details) => {
    setPlanData(details);
  };

  useEffect(() => {
    if (localStorage.getItem("planDetails")) {
      setPlanData(JSON.parse(localStorage.getItem("planDetails")));
    }
  }, []);

  return (
    <ToastContext.Provider value={{ addToast, setPlan, planData }}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        // pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const addToast = useContext(ToastContext);
  if (!addToast) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return addToast;
};
