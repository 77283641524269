import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ErrorImg from "../../assets/images/404_Error.jpg";

const NotFoundPage = () => {
  const navigate = useNavigate();
  const [view, setView] = useState(null);

  useEffect(() => {
    if (
      localStorage.getItem("planDetails") &&
      localStorage.getItem("token") &&
      localStorage.getItem("userId") &&
      localStorage.getItem("currencySymbol")
    ) {
      setView(true);
    } else {
      navigate("/");
    }
  }, [navigate]);

  return (
    <>
      {view && (
        <div className="container-scroller">
          <div className="container-fluid page-body-wrapper full-page-wrapper px-0">
            <div className="content-wrapper d-flex align-items-center auth px-0">
              <div className="row w-100 mx-0">
                <div style={{ textAlign: "center" }}>
                  <div>
                    <img
                      src={ErrorImg}
                      style={{
                        width: "500px",
                        height: "500px",
                        mixBlendMode: "multiply",
                      }}
                      alt="ErrorImg"
                    />
                  </div>
                  <h2 style={{ margin: "0" }}>
                    <b>You weren't supposed to see this.</b>
                  </h2>
                  <h3 style={{ margin: "0" }}>
                    Sorry, but this page doesn't exist.
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NotFoundPage;
