// export const BaseURL = "https://baatcheet.shop/khateraho";
export const BaseURL = "https://foodersapi.scanka.com";

// export const BaseURL = "https://foodersapi.iwcnlabs.shop";
// export const BaseURL = "https://foodersapi.khateraho.com";

export const kdsFooders = `${BaseURL}/api/kdsFooders`;
export const kdsBar = `${BaseURL}/api/kdsBar`;

export const chageKotItemStatusAPI = `${BaseURL}/api/chageKotItemStatus`;
export const chageKDSKotStatusAPI = `${BaseURL}/api/chageKDSKotStatus`;

export const chageKDSKotAllItemsStatusAPI = `${BaseURL}/api/chageKDSKotAllItemsStatus`;
export const KdsLoginAPI = `${BaseURL}/api/kdsLogin`;


export const timmerValue = 100;



export const ScriptSRC = ""; 
// export const ScriptSRC = "https://alertify.live/notifier.js?data=devnode_"; // For "https://fooders.scanka.com"
// export const ScriptSRC = "https://alertify.live/notifier.js?data=livenode_"; // For "https://fooders.khateraho.com"
export const ScriptId = "";
// export const ScriptId = "alertify-notifier-script";
export const OrderAcceptTime = 60;
